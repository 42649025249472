
import { computed, defineComponent, onMounted, ref, SetupContext, } from 'vue';
import { IBenefit } from '@/types/Interfaces';
import useBenefit from '@/types/Benefit';
import useMasterData from '@/types/MasterData';

import BenefitEditForm from './BenefitEditForm.vue';
import useFlashMessage from '@/types/FlashMessage';

export default defineComponent({
  components: {
    BenefitEditForm
  },
  setup(_, context: SetupContext) {
    // const headers = g
    const {
      setMessage
    } = useFlashMessage();
    
    const {
      data,
      editingItem,
      onChangeDisplay
    } = useMasterData();
    
    const {
      fetchBenefits,
      deleteBenefit
    } = useBenefit();
    
    onMounted(async () => {
      data.value = await fetchBenefits();  
    })
    
    const addTemplate: IBenefit = {
      target: '',
      benefit: '',
      sort: 0,
      display: false
    }

    const onUpdate = async () => {
      editingItem.value = null;
      data.value = await fetchBenefits();
      setMessage({ type: 'success', message: '更新しました。' })
    }
    const onDelete = async (id: number) => {
      const data = await deleteBenefit(id);
      data.value = await fetchBenefits();
      setMessage({ type: 'success', message: '削除しました。' })
    
    }

    const targetText = {
      'client_visitor': 'お客様来場特典',
      'client_contract': 'お客様成約特典',
      'owner_visitor': 'オーナー様来場特典',
      'owner_contract': 'オーナー様成約特典',
      
    }

    return {
      targetText,
      data,
      editingItem,
      addTemplate,
      onChangeDisplay,
      onUpdate,
      onDelete
    }
  }
})
