<template>
  <base-layout>
    <template v-slot:title>
      <div
        class="text-lg "
      >
        特典内容マスタ
      </div>
    </template>
    <div class="p-3">
      
      <div class="text-right mb-3">
        <button
          @click="editingItem = addTemplate"
          class="success">
          新規作成
        </button>
      </div>
      <div>
        <table
          class="base"
          v-if="data"
        >
          <thead>
            <tr>
              <th>対象</th>
              <th>特典</th>
              <th>ソート</th>
              <th>表示</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(p, i) in data"
              :key="i"
              
            >
              <td class="">
                {{ targetText[p.target] }}
              </td>
              <td>{{ p.benefit }}</td>
              <td>{{ p.sort }}</td>
              <td>
                <my-toggle
                  :modelValue="p.display"
                ></my-toggle>
              </td>
              <td>
                <div
                  class="flex justify-end"  
                >
                  <svg
                    @click="editingItem = p"
                    class="w-6 h-6 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                  <svg
                    @click="onDelete(i)"
                    class="w-6 h-6 cursor-pointer  ml-2 text-red-600"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
        <frame-modal
          v-if="editingItem != null"
        >
          <benefit-edit-form
            :modelValue = editingItem
            @update="onUpdate"
            @close="editingItem = null"
          ></benefit-edit-form>
        </frame-modal>
      </div>
    </div>
  </base-layout>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref, SetupContext, } from 'vue';
import { IBenefit } from '@/types/Interfaces';
import useBenefit from '@/types/Benefit';
import useMasterData from '@/types/MasterData';

import BenefitEditForm from './BenefitEditForm.vue';
import useFlashMessage from '@/types/FlashMessage';

export default defineComponent({
  components: {
    BenefitEditForm
  },
  setup(_, context: SetupContext) {
    // const headers = g
    const {
      setMessage
    } = useFlashMessage();
    
    const {
      data,
      editingItem,
      onChangeDisplay
    } = useMasterData();
    
    const {
      fetchBenefits,
      deleteBenefit
    } = useBenefit();
    
    onMounted(async () => {
      data.value = await fetchBenefits();  
    })
    
    const addTemplate: IBenefit = {
      target: '',
      benefit: '',
      sort: 0,
      display: false
    }

    const onUpdate = async () => {
      editingItem.value = null;
      data.value = await fetchBenefits();
      setMessage({ type: 'success', message: '更新しました。' })
    }
    const onDelete = async (id: number) => {
      const data = await deleteBenefit(id);
      data.value = await fetchBenefits();
      setMessage({ type: 'success', message: '削除しました。' })
    
    }

    const targetText = {
      'client_visitor': 'お客様来場特典',
      'client_contract': 'お客様成約特典',
      'owner_visitor': 'オーナー様来場特典',
      'owner_contract': 'オーナー様成約特典',
      
    }

    return {
      targetText,
      data,
      editingItem,
      addTemplate,
      onChangeDisplay,
      onUpdate,
      onDelete
    }
  }
})
</script>