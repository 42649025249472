
import { ref } from 'vue';

const useMasterData = () => {
  const editingItem = ref<any>(null);
  const data = ref<any>([]);
  
  // const onDelete = (index: number) => {
  //   if (window.confirm('delete?')) data.value.splice(index, 1);
  // };
  
  const onChangeDisplay = (index: number, event: boolean) => {
    if (data.value == null) return;
    data.value[index].display = event;
  };
  return {
    data,
    editingItem,
    onChangeDisplay
  }
}
export default useMasterData;