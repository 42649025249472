
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IBenefit } from '@/types/Interfaces';
import { cloneDeep } from 'lodash';
import useBenefit from '@/types/Benefit'
import useOwnerRelation from '@/types/OwnerRelation';

export default defineComponent({
  components: {
  },
  props: {
    modelValue: {
      type: Object as () => IBenefit
    }
  },
  setup(props: any, context: SetupContext) {
    

    const {
      updateBenefit,
      createBenefit
    } = useBenefit()

    const data = ref<IBenefit | null>(null);
    
    const onClose = () => {
      context.emit('close');  
    };

    

    const targets = [
      {
        value: 'client_visitor',
        text: 'お客様来場特典'
      },
      {
        value: 'client_contract',
        text: 'お客様成約特典'
      },
      {
        value: 'owner_visitor',
        text: 'オーナー様来場特典'
      },
      {
        value: 'owner_contract',
        text: 'オーナー様成約特典'
      },
      
      
    ]
    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
    })

    const onUpdate = async () => {
      // do some async work
      if (data.value == null) return;
      let r;
      if (data.value.id) {
        r = await updateBenefit(data.value.id, data.value)
      } else {
        r = await createBenefit(data.value)
      }
      console.log(r)
      context.emit('update', data.value);
    };
    
    return {
      targets,
      data,
      onClose,
      onUpdate
    }
  }
})
