<template>
  <div class="text-gray-900 inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-6  pb-4 sm:p-6 sm:pb-4">
      <form
        @submit.prevent="onUpdate"
        v-if="data != null"
        class="pt-6"
      >
        <select-box
          class="mb-5 "
          label="対象"
          v-model="data.target"
          :options="targets"
          :error="null"
        ></select-box>
        
        <text-field
          v-model="data.benefit"
          label="特典"
          class="mb-5"
        ></text-field>
        
        <text-field
          v-model="data.sort"
          label="ソート"
          class="mb-5"
        ></text-field>  
        <div class="text-left mb-10">
          <span class="text-sm ml-2">表示</span>
          <my-toggle
          v-model="data.display"
          ></my-toggle>
            
        </div>
        <div>
          <button class="success" @click="update">
            {{ data.id ? '更新' : '追加' }}
          </button>
          <button class="ml-5" @click="onClose">
            閉じる
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IBenefit } from '@/types/Interfaces';
import { cloneDeep } from 'lodash';
import useBenefit from '@/types/Benefit'
import useOwnerRelation from '@/types/OwnerRelation';

export default defineComponent({
  components: {
  },
  props: {
    modelValue: {
      type: Object as () => IBenefit
    }
  },
  setup(props: any, context: SetupContext) {
    

    const {
      updateBenefit,
      createBenefit
    } = useBenefit()

    const data = ref<IBenefit | null>(null);
    
    const onClose = () => {
      context.emit('close');  
    };

    

    const targets = [
      {
        value: 'client_visitor',
        text: 'お客様来場特典'
      },
      {
        value: 'client_contract',
        text: 'お客様成約特典'
      },
      {
        value: 'owner_visitor',
        text: 'オーナー様来場特典'
      },
      {
        value: 'owner_contract',
        text: 'オーナー様成約特典'
      },
      
      
    ]
    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
    })

    const onUpdate = async () => {
      // do some async work
      if (data.value == null) return;
      let r;
      if (data.value.id) {
        r = await updateBenefit(data.value.id, data.value)
      } else {
        r = await createBenefit(data.value)
      }
      console.log(r)
      context.emit('update', data.value);
    };
    
    return {
      targets,
      data,
      onClose,
      onUpdate
    }
  }
})
</script>
